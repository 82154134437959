<template>
  <div>
    <c-search-box ref="searchbox" @enter="getVendorUserList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-4 col-xl-4">
          <c-vendor
            label="도급업체"
            name="vendorCd"
            :disabled="Boolean(popupParam.vendorCd)"
            v-model="searchParam.vendorCd">
          </c-vendor>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-text
            label="도급업체명"
            name="vendorName"
            v-model="searchParam.vendorName">
          </c-text>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="vendorUserTable"
      title="도급업체 근무인원"
      tableId="vendorUserTable"
      :columns="grid.columns"
      :data="grid.data"
      :usePaging="false"
      :hideBottom="true"
      :columnSetting="false"
      :selection="popupParam.type"
      rowKey="vendorUserCd"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getVendorUserList" />
          <c-btn label="선택" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'vendor-user-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          plantCd: null,
          vendorCd: '',
        }
      },
    },
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        vendorCd: '',
        vendorName: '',
      },
      grid: {
        columns: [
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '도급업체명',
            align: 'center',
            sortable: false,
          },
          {
            name: 'personName',
            field: 'personName',
            label: '성명',
            align: 'center',
            sortable: false,
          },
          {
            name: 'birthDate',
            field: 'birthDate',
            label: '생년월일',
            style: 'width:130px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'sexName',
            field: 'sexName',
            label: '성별',
            style: 'width:80px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'jobName',
            field: 'jobName',
            label: '직책',
            style: 'width:100px',
            align: 'center',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.listUrl = selectConfig.mdm.cim.vendor.user.list.url;
      if (this.popupParam.plantCd) {
        this.searchParam.plantCd = this.popupParam.plantCd;
      }
      if (this.popupParam.vendorCd) {
        this.searchParam.vendorCd = this.popupParam.vendorCd;
      }
      this.getVendorUserList();
    },
    getVendorUserList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['vendorUserTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '근무자를 지정하세요.', // 근무자를 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
  }
};
</script>
